.toolBar {
    list-style-type: none;
    text-align: center;
    background-color: #FFFFFF;
    padding: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.toolBar li {
    display: inline-block;
    padding: 5px;
}
.toolBar button{
    font-size: large;
    padding: 5px;
}